@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #292524;
}

select {
  background-color: #292524;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer components {
  .container {
    @apply w-full h-full grid gap-4 p-8 rounded-xl bg-stone-900 content-start;
  }
  .pill {
    @apply p-4 border rounded-lg cursor-pointer hover:bg-stone-800 md:justify-items-start justify-items-center bg-stone-900 gap-x-6;
  }
  .info {
    @apply grid gap-6 mt-4 text-sm md:gap-8 md:grid-cols-3 items-center;
  }
  .list {
    @apply grid gap-8 overflow-scroll max-h-[800px] items-start no-scrollbar;
  }
  .card {
    @apply p-4 text-sm border rounded-lg cursor-pointer h-fit hover:bg-stone-800 border-stone-400 bg-stone-900;
  }
  .selected-card {
    @apply p-4 text-sm border rounded-lg cursor-pointer h-fit hover:bg-stone-800 bg-opacity-10 border-yellow-600 bg-yellow-900;
  }
  .input {
    @apply bg-stone-900 rounded-md px-2 py-2 outline-primary focus:outline-none text-white;
  }

  .select {
    @apply px-3 pt-3 pb-2 border-b border-yellow-600 outline-none cursor-pointer;
  }

  .form-element {
    @apply grid grid-cols-3 items-center;
  }

  .form-label {
    @apply text-stone-200;
  }

  .form-input {
    @apply rounded-md px-2 py-2 border border-stone-600 bg-stone-900 focus:outline-none focus:ring-1 ring-yellow-600 text-stone-200;
  }

  .form-input-disabled {
    @apply rounded-md px-2 py-2 col-span-2 cursor-not-allowed bg-stone-800;
  }

  .form-checkbox {
    @apply flex space-x-4 items-center;
  }

  .checkbox {
    @apply accent-yellow-600 w-4 h-4 cursor-pointer;
  }

  .checkbox-label {
    @apply font-semibold mx-2 w-32;
  }

  .btn-primary {
    @apply py-2 px-4 h-fit text-center bg-yellow-600 hover:bg-yellow-800 text-white rounded-md cursor-pointer;
  }

  .btn-secondary {
    @apply py-2 px-4 h-fit text-center bg-stone-600 hover:bg-stone-700 text-white rounded-md cursor-pointer;
  }

  .btn-tertiary {
    @apply py-2 px-4 text-center border border-primary hover:bg-yellow-800 text-white rounded-md cursor-pointer;
  }

  .btn-warning {
    @apply py-2 px-4 h-fit text-center bg-red-900 hover:bg-red-800 text-white rounded-md cursor-pointer;
  }

  .btn-loading {
    @apply flex items-center justify-center cursor-progress py-2 px-4 h-fit text-center bg-stone-600 rounded-md;
  }

  .btn-radio {
    @apply px-4 py-2 font-semibold text-center border-2 border-green-700 rounded-md;
  }

  .hr {
    @apply border border-stone-600 rounded-md;
  }

  .hr-primary {
    @apply border border-yellow-600 rounded-md;
  }

  .icon {
    @apply text-xl text-white cursor-pointer md:block hover:text-stone-400;
  }

  .warning {
    @apply text-red-600 text-sm;
  }

  .response {
    @apply text-green-600 text-sm;
  }
}

.confimation-modal {
  @apply bg-gray-800 dark:bg-stone-600 text-white rounded-md p-6 shadow-lg;
  background-color: rgb(87, 83, 78);
}